import React, { Component } from "react";
import AuthContext from "./AuthContext";

export default class Profile extends Component {
  static contextType = AuthContext;

  render() {
    const { currentUser } = this.context;
    console.log("currentUser", currentUser);

    return (
      currentUser && (
        <div className="profile-menu ">
          {/* <div className="d-flex">
             <img
              src={currentUser.photoURL}
              alt={currentUser.name}
              className="me-2"
            />
            <p>{user.name}</p>
          </div> */}
          <p>{currentUser.email}</p>
        </div>
      )
    );
  }
}
