import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";
import Menu from "./Menu";
import LandingPage from "../pages/LandingPage";
import Loader from "../_shared/components/Loader";
import AppLoader from "../_shared/components/AppLoader";
import { auth } from "../data/firebase";
import LoginPage from "../pages/LoginPage";
import withParams from "../hooks/withParams";
import AuthContext from "../auth/AuthContext";
import ResetPasswordPage from "../pages/ResetPasswordPage";

class Layout extends Component {
  static contextType = AuthContext;

  render() {
    const { params: route } = this.props;
    const { currentUser } = this.context;
    return (
      <div className="layout" onContextMenu={(e) => e.preventDefault()}>
        {currentUser ? (
          <>
            <Menu />
            <Outlet />
          </>
        ) : route["*"] === "hanova-teny-miafina" ? (
          <ResetPasswordPage />
        ) : route["*"] === "fidirana" ? (
          <LoginPage />
        ) : (
          <LandingPage />
        )}
      </div>
    );
  }
}

export default withParams(Layout);
