import React, { Component } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ProfileModal from "./ProfileModal";
import retrieveGoogleStorageLink from "../functions/retrieveGoogleStorageLink";

// TODO : vertical chart for mobile
export default class OrgChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentNode: props.data,
      previousNode: props.data,
    };
  }

  setCurrentNode = (node, parent) => {
    this.setState({ previousNode: parent });
    this.setState({ currentNode: node });
  };

  renderChildren = (data, parent) => {
    return (
      <TreeNode
        label={
          <OrgNode
            node={data}
            setCurrentNode={this.setCurrentNode}
            parent={parent}
          />
        }
      >
        {!data.hideChildren &&
          data.children?.map((tree) => this.renderChildren(tree, data))}
      </TreeNode>
    );
  };

  render() {
    const { data } = this.props;
    const { currentNode, previousNode } = this.state;

    const showBackButton =
      previousNode?.icon !== currentNode?.icon &&
      data.icon !== currentNode?.icon;
    return (
      <div className="org-chart">
        {showBackButton && (
          <Button
            className="position-fixed"
            size="sm"
            variant="outline-secondary"
            onClick={() => this.setCurrentNode(previousNode, currentNode)}
          >
            {"<"} Hiverina
          </Button>
        )}
        <Tree
          lineWidth={"2px"}
          lineColor={"#00000017"}
          lineBorderRadius={"10px"}
          label={
            <OrgNode
              node={currentNode}
              setCurrentNode={this.setCurrentNode}
              parent={previousNode}
            />
          }
        >
          {currentNode.children?.map((tree) =>
            this.renderChildren(tree, currentNode)
          )}
        </Tree>
      </div>
    );
  }
}

class OrgNode extends Component {
  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  async componentDidUpdate() {
    const {
      node,
      node: { image },
    } = this.props;
    let imageToDisplay = "/images/anonymous.png";

    if (image) {
      const imageUrl = await retrieveGoogleStorageLink("profile", image);

      if (imageUrl) {
        imageToDisplay = imageUrl;
      }
    }

    node.imageToDisplay = imageToDisplay;
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      node,
      node: { icon, title, name, entity, isActive, children, hideChildren },
      setCurrentNode,
      parent,
    } = this.props;
    const { showModal } = this.state;

    const nodeClassNames = classNames({
      "chart-node d-flex justify-content-center": true,
      active: isActive,
    });

    return (
      <>
        <Card className={nodeClassNames}>
          <Card.Body>
            <div key={icon} className="d-flex flex-column align-items-center">
              {icon && (
                <img
                  src={`/images/entity/${icon}.png`}
                  alt={title}
                  className="icon me-1"
                />
              )}
              <span className="text-center">
                <strong>{entity}</strong>
                <br />
                <span>{name}</span>
              </span>
            </div>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex">
              <Button className="me-1 btn-red" onClick={this.handleShowModal}>
                Ny filoha
              </Button>
              {hideChildren && children?.length && (
                <Button
                  className="btn-red-outline"
                  onClick={() => setCurrentNode(node, parent)}
                >
                  Ny ekipa
                </Button>
              )}
            </div>
          </Card.Footer>
        </Card>

        <ProfileModal
          show={showModal}
          onHide={this.handleCloseModal}
          detail={node}
        />
      </>
    );
  }
}
