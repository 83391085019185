import React, { Component } from "react";
import BranchesPage from "./BranchesPage";
import TalentsPage from "./TalentsPage";

export default class HomePage extends Component {
  render() {
    return (
      <>
        <BranchesPage />
        {/* <TalentsPage /> */}
      </>
    );
  }
}
