import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
// import { branches } from "../data/branches";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../data/firebase";
import Loader from "../_shared/components/Loader";

export default class TalentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchBranches();
  }

  fetchBranches = async () => {
    await getDocs(collection(db, "talents")).then((querySnapshot) => {
      const data = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .sort((branch1, branch2) => branch1.rank - branch2.rank);
      this.setState({ branches: data, loading: false });
    });
  };

  render() {
    const { branches, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <Container className="talents-page" fluid>
        <h2>Mari-talenta</h2>
        <Row>
          {branches.map((branch, index) => (
            <Col key={index} className="branch" sm={6}>
              <Link to={"talenta/" + branch.id}>
                <div className="m-1 p-5" style={{ background: branch.color }}>
                  <h3>{branch.name}</h3>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
