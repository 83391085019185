import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { OrganizationChart } from "primereact/organizationchart";
import { Tree, TreeNode } from "react-organizational-chart";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../data/firebase";
import OrgChart from "../_shared/components/OrgChart";
import Loader from "../_shared/components/Loader";

export default class StructurePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hierachy: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchHierarchy();
  }

  fetchHierarchy = async () => {
    await getDocs(collection(db, "hierarchy")).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      this.setState({ hierachy: data[0], loading: false });
    });
  };

  render() {
    const data = {
      key: 1,
      title: "Filoha Iombonana",
      name: "RANDIANIRINA Charles",
      children: [
        {
          key: 2,
          title: "Filoha Iombonana Lefitra - Fanabeazana",
          name: "BADADE Jean Marie",
          children: [
            {
              key: 4,
              title: "Filohan-tsampana Lovitao",
              name: "Clarisse",
            },
            {
              key: 5,
              title: "Filohan-tsampana Mpianjoria",
              name: "Clarisse",
            },
            {
              key: 6,
              title: "Filohan-tsampana Mpamakilay",
              name: "Clarisse",
            },
            {
              key: 7,
              title: "Filohan-tsampana Mpiandalana",
              name: "Clarisse",
            },
            {
              key: 8,
              title: "Filoha mpandrindra ny AIMO",
              name: "Clarisse",
            },
            {
              key: 8,
              title: "Filoha Lakana",
              name: "Clarisse",
            },
          ],
        },
        {
          key: 3,
          title: "Filoha misahana ny fifandraisana iraisam-pirenena",
          name: "RABE-HARINORO Amboara",
        },
      ],
    };
    const { hierachy, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    console.log({ hierachy });

    return (
      <Container className="branch-page" fluid>
        <h1>Ny rafitra mifandray amin'ny fanabeazana</h1>
        <div className="mt-2">
          <OrgChart data={hierachy} />
        </div>
      </Container>
    );
  }
}
