import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Placeholder from "react-bootstrap/Placeholder";
// import { branches } from "../data/branches";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../data/firebase";
import Loader from "../_shared/components/Loader";

export default class BranchesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchBranches();
  }

  fetchBranches = async () => {
    await getDocs(collection(db, "branches")).then((querySnapshot) => {
      const data = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .sort((branch1, branch2) => branch1.rank - branch2.rank);
      this.setState({ branches: data, loading: false });
    });
  };

  render() {
    const { branches, loading } = this.state;

    // if (loading) {
    //   return <Loader />;
    // }

    return (
      <Container className="branches-page" fluid>
        <h2>Sampana</h2>
        <Row>
          {loading ? (
            <Col className="branch mb-2" sm={12} md={2}>
              <Card>
                <Card.Img variant="top" src={"https://placehold.co/600x400"} />
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                    <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                    <Placeholder xs={8} />
                  </Placeholder>
                  <Placeholder.Button variant="primary" xs={6} />
                </Card.Body>
              </Card>
            </Col>
          ) : (
            branches?.map((branch, index) => (
              <Col key={index} className="branch mb-2" sm={12} md={2}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={`/images/branches/${branch.id}.jpg`}
                  />
                  <Card.Body>
                    <Card.Title>{branch.name}</Card.Title>
                    <Card.Text>{branch.description}</Card.Text>
                    <Button
                      className="btn-red"
                      // style={{
                      //   background: branch.color,
                      //   border: "solid 1px black",
                      // }}
                    >
                      <Link to={branch.id}>Hijery</Link>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>
    );
  }
}
