import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import withAuth from "../hooks/withAuth";
import { Link } from "react-router-dom";
import LoginPage from "./LoginPage";

class LandingPage extends Component {
  render() {
    return (
      <div className="landing-page d-flex align-items-center ml-5">
        <Card>
          <Card.Img variant="top" src={"/images/logo-100-white.png"} />
          <Card.Body>
            <Card.Title>
              Tongasoa eto amin'ny Tranokala ho an'ny Fanabeazana
            </Card.Title>
            <Card.Text>
              Toerana an-jotra natao ho an'ireo misehatra amin'ny fanabeazana
              eto anivon'ny Fikambanana.
            </Card.Text>

            {/* <Button onClick={() => loginWithRedirect()} variant="light">
              Hiditra
            </Button> */}

            {/* <LoginPage /> */}
            <Link className="btn btn-light" to={"fidirana"} variant="light">
              Hiditra
            </Link>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withAuth(LandingPage);
