import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default class ProfileModal extends Component {
  render() {
    const {
      show,
      onHide,
      detail: { title, name, email, phone, job, imageToDisplay },
    } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="lg" className="profile-modal">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            {name && (
              <img
                src={imageToDisplay ?? "/images/anonymous.png"}
                alt={name}
                className="profile-picture me-3"
              />
            )}
            <div>
              <table>
                {name && (
                  <tr>
                    <td>
                      <strong>Anarana:</strong>
                    </td>
                    <td>{name}</td>
                  </tr>
                )}
                {email && (
                  <tr>
                    <td>
                      <strong>Mailaka:</strong>
                    </td>
                    <td>
                      <a href={`mailto:${email}`}>{email}</a>
                    </td>
                  </tr>
                )}
                {phone && (
                  <tr>
                    <td>
                      <strong>Finday:</strong>
                    </td>
                    <td>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div>
            {job && (
              <>
                <strong>Ny sehatra iandrasana vokatra</strong>
                <ul>
                  {job?.map((point) => (
                    <li>{point}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseModal}>
            Hiverina
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
