export const branches = [
  {
    color: "#FFFF00",
    slug: "lvt",
    name: "Lovitao",
  },
  {
    color: "#00A651",
    slug: "mpj",
    name: "Mpianjoria",
  },
  {
    color: "#35CBF9",
    slug: "mpk",
    name: "Mpamakilay",
  },
  { color: "#ED1C24", slug: "mpd", name: "Mpiandalana" },
  { color: "#FFFFFF", slug: "lakana", name: "Lakana" },
  { color: "#622599", slug: "aimo", name: "AIM Oniversitera" },
];
