import React, { Component } from "react";
import AuthContext from "./AuthContext";
import { auth } from "../data/firebase";
import AppLoader from "../_shared/components/AppLoader";

export default class AuthProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      loading: true,
    };
  }

  async componentDidMount() {
    auth.onAuthStateChanged((res) => {
      this.setState({ currentUser: res, loading: false });
    });
  }

  render() {
    const { children } = this.props;
    const { loading } = this.state;

    return (
      <AuthContext.Provider value={this.state}>
        {loading ? <AppLoader /> : children}
      </AuthContext.Provider>
    );
  }
}
