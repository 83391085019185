import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import BranchPage from "./pages/BranchPage";
import HomePage from "./pages/HomePage";
import App from "./App";
import StructurePage from "./pages/StructurePage";
import LoginPage from "./pages/LoginPage";
import AuthProvider from "./auth/AuthProvider";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Layout />}>
          <Route path="" element={<HomePage />} />
          <Route path="fidirana" element={<LoginPage />} />
          <Route path="hanova-teny-miafina" element={<ResetPasswordPage />} />
          <Route path="rafitra" element={<StructurePage />} />
          <Route path=":slug" element={<BranchPage />} />
          <Route path="talenta/:slug" element={<BranchPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <App />
  </AuthProvider>
);

reportWebVitals();
