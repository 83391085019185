import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default async function retrieveGoogleStorageLink(path, file) {
  const storage = getStorage();
  const gsUri = "gs://fanabeazana-antily-app.appspot.com/" + path + "/" + file;
  const gsReference = ref(storage, gsUri);

  try {
    const url = await getDownloadURL(gsReference);
    return url;
  } catch (error) {
    console.error(error);
  }
  return null;
}
