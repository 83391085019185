import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../data/firebase";
import ListGroup from "react-bootstrap/ListGroup";
import withParams from "../hooks/withParams";
import Loader from "../_shared/components/Loader";
import PdfViewer from "../_shared/components/PdfViewer";
import retrieveGoogleStorageLink from "../_shared/functions/retrieveGoogleStorageLink";

class BranchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: null,
      loading: true,
      activeDocument: null,
    };
  }

  componentDidMount() {
    this.fetchBranch();
  }

  fetchBranch = async () => {
    const {
      params: { slug },
    } = this.props;

    const docRef = doc(db, "branches", slug);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const branch = docSnap.data();
      branch.id = slug;
      this.setState({ branch });
    }

    this.setState({ loading: false });
  };

  // TODO refactor
  setActiveDocument = async (activeDocument) => {
    const { branch } = this.state;

    const url = await retrieveGoogleStorageLink(branch.id, activeDocument.link);
    console.log(activeDocument, url);
    activeDocument.url = url;
    this.setState({ activeDocument });
  };

  render() {
    const { loading, branch, activeDocument } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!branch) return <div>Tsy misy</div>;

    return (
      <Container className="branch-page" fluid>
        <h1>{branch.name}</h1>
        <Row>
          <Col sm={3}>
            <ListGroup>
              {branch.documents?.map((document, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => this.setActiveDocument(document)}
                >
                  {document.title}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col>
            {activeDocument ? (
              <>
                <PdfViewer
                  src={activeDocument.url + "#toolbar=0&zoom=170%"}
                  title={activeDocument.title}
                />
              </>
            ) : (
              []
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withParams(BranchPage);
