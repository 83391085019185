import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../data/firebase";
import AuthContext from "../auth/AuthContext";

// DOC https://blog.bitsrc.io/firebase-authentication-with-react-for-beginners-implementing-email-password-and-google-sign-in-e62d9094e22
export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loading: false,
      error: null,
    };
  }

  static contextType = AuthContext;

  signIn = async () => {
    const { email, password } = this.state;
    try {
      this.setState({ loading: true });
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      this.setState("userCredential", userCredential);
    } catch (error) {
      if (
        error.message?.includes("auth/invalid-credential") ||
        error.message?.includes("auth/invalid-email")
      ) {
        this.setState({ error: "Diso ny mailaka na teny miafina" });
      } else {
        console.error(error);
        this.setState({ error: "Misy olana" });
      }
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading, error } = this.state;
    const { currentUser } = this.context;
    return (
      <div className="login-page d-flex justify-content-center align-items-center">
        {currentUser && <Navigate to="/" replace={true} />}
        <Card className="p-3">
          <Card.Img variant="top" src={"/images/logo-100.png"} />

          {error && <Alert variant={"danger"}>{error} </Alert>}
          <Card.Body>
            <Form.Control
              type="text"
              className="mb-3"
              placeholder="Mailaka"
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            <Form.Control
              type="password"
              className="mb-3"
              placeholder="Teny miafina"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <Button
              onClick={this.signIn}
              className="btn-red"
              disabled={loading}
            >
              Hiditra
            </Button>
            <br />
            <Button variant="link" href="/hanova-teny-miafina">
              Tsy tadidiko ny teny miafina
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
