import React, { useRef, useEffect } from "react";
// Import the main component
// import { Worker, Viewer } from "@react-pdf-viewer/core";

// Import the styles
// import "@react-pdf-viewer/core/lib/styles/index.css";
// DOC: https://react-pdf-viewer.dev/docs/options/

const PdfViewer = ({ src, title }) => {
  return (
    <div className="pdf-viewer" onContextMenu={(e) => e.preventDefault()}>
      <iframe
        // ref={iframeRef}
        src={src + "#toolbar=0&zoom=170%"}
        title={title}
      />
      {/* <div className="overlay" onContextMenu={(e) => e.preventDefault()}></div> */}
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer fileUrl={src} />
      </Worker> */}
    </div>
  );
};

// documents: [
//   {
//     title: "Bokin-tsampana Lovitao",
//     link: "https://antily.mg/tohana/lvt-boky-2023.pdf",
//   },
// ],

export default PdfViewer;
