import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../data/firebase";
import AuthContext from "../auth/AuthContext";

export default class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      loading: false,
      error: null,
      showSuccess: false,
    };
  }

  static contextType = AuthContext;

  resetPassword = async () => {
    const { email } = this.state;
    try {
      this.setState({ loading: true });
      const userCredential = await sendPasswordResetEmail(auth, email, {
        url: window.location.origin,
      });
      console.log("userCredential", userCredential);
      this.setState({ showSuccess: true });
    } catch (error) {
      console.error(error);
      this.setState({ error: "Misy olana" });
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading, error, showSuccess, email } = this.state;
    const { currentUser } = this.context;
    return (
      <div className="login-page d-flex justify-content-center align-items-center">
        {currentUser && <Navigate to="/" replace={true} />}
        <Card className="p-3">
          <Card.Img variant="top" src={"/images/logo-100.png"} />
          <Card.Body>
            {error && <Alert variant={"danger"}>{error} </Alert>}
            {showSuccess ? (
              <Alert variant={"success"}>
                Jereo ny mailaka {email} ary araho ny rohy hahafahanao manova ny
                teny miafina
              </Alert>
            ) : (
              <Form>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder="Mailaka"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <Button
                  onClick={this.resetPassword}
                  className="btn-red"
                  disabled={loading}
                >
                  Hanova teny miafina
                </Button>
              </Form>
            )}

            <br />
            <Button variant="link" href="/fidirana">
              Hiverina
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
