import React, { Component } from "react";
import { signOut } from "firebase/auth";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "./logo.png";
import { branches } from "../data/branches";
import { Link } from "react-router-dom";
import { talents } from "../data/talents";
import Profile from "../auth/Profile";
import { auth } from "../data/firebase";

export default function Menu() {
  const isAuthenticated = auth.currentUser;

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Navbar expand="lg" className="menu">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src="/images/logo.png"
            alt={"Logo Antily"}
            className="brand-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto my-2 my-lg-0">
            {isAuthenticated && (
              <>
                <NavDropdown title="Bokin-tsampana">
                  {branches.map((branch, index) => (
                    <NavDropdown.Item key={index} href={"/" + branch.slug}>
                      {branch.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>

                <Nav.Link href="rafitra">Rafitra</Nav.Link>

                {/* <NavDropdown title="Mari-talenta">
                  {talents.map((branch, index) => (
                    <NavDropdown.Item
                      href={"/talenta/" + branch.slug}
                      key={index}
                    >
                      {branch.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown> */}
                <NavDropdown title="Ny momba ahy">
                  <NavDropdown.Item
                    onClick={() =>
                      logOut({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                  >
                    Hivoaka
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Profile />
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
